/* eslint-disable no-undef */
import React, { useState } from "react";
import {
  Grid,
  Button,
  Box,
  Link,
  Typography,
  TextField,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import sh from "bundles/common/utils/sh";

import validator from "validator";
import { useSnackbar } from "notistack";
import {
  getResponseMessageId,
  countryMap,
} from "bundles/common/utils/constants";
import PasswordField from "bundles/common/components/PasswordField";
import { useSelector, useDispatch } from "react-redux";
import OauthButton from "bundles/common/components/OauthButton";
import TextDivider from "bundles/common/components/TextDivider";
import { useLocation } from "react-router-dom";
import {
  trackCandidateSignup,
  activeTrackingScripts,
} from "bundles/common/utils/marketing";
import { replaceCurrentCandidate } from "../../constants/candidateConstants";

const SignUp = ({ onSectionClicked, onActionFinished, customScope }) => {
  const { enqueueSnackbar } = useSnackbar();
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const authenticity_token = useSelector((state) => state.authenticity_token);
  const railsEnv = useSelector((state) => state.railsEnv);
  const theme = useTheme();

  const initialFormValues = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
  };

  const dispatch = useDispatch();
  const location = useLocation();
  const oauthError = new URLSearchParams(location.search).get("oauth_error");

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [signUpWay, setSignUpWay] = useState("oauth");

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_an_email = (
      <FormattedMessage id="form_validation.not_an_email"></FormattedMessage>
    );
    let not_a_strong_password = (
      <FormattedMessage id="form_validation.not_a_strong_password"></FormattedMessage>
    );

    if ("firstName" in fieldValues) {
      temp.firstName = fieldValues.firstName ? "" : required;
    }

    if ("lastName" in fieldValues) {
      temp.lastName = fieldValues.lastName ? "" : required;
    }

    if ("phone" in fieldValues) {
      temp.phone = fieldValues.phone ? "" : required;
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = not_an_email;
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (
        fieldValues.password &&
        !validator.isStrongPassword(fieldValues.password)
      ) {
        temp.password = not_a_strong_password;
      }
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      sh.post(`/${i18nLocale}/candidates`, {
        candidate: {
          first_name: values.firstName,
          last_name: values.lastName,
          phone: values.phone,
          email: values.email,
          password: values.password,
          active_tracking_scripts: activeTrackingScripts(),
        },
      })
        .then(() => {
          if (railsEnv === "production") trackCandidateSignup();
          replaceCurrentCandidate(dispatch, authenticity_token, () => {
            window.location.href = `${window.location.origin}/${i18nLocale}/candidate?showOnboarding=true`;
          });
        })
        .catch((err) => {
          if (err.response?.data?.message) {
            enqueueSnackbar(
              getResponseMessageId(err?.response?.data?.message),
              { variant: "error" }
            );
          }
          console.error("Error", err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box>
        <Box
          disableTypography
          title={
            <Typography variant="h2" align="center">
              <FormattedMessage
                id="devise.signup.build_carreer_html"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
              .
            </Typography>
          }
        />
        {signUpWay == "oauth" ? (
          <Box style={{ marginLeft: "8px", marginRight: "8px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "16px" }}>
                <Grid item xs={12} style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}signup`}
                    provider="linkedin"
                  />
                  {oauthError && oauthError == "linkedin_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}signup`}
                    provider="facebook"
                  />
                  {oauthError && oauthError == "facebook_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "16px" }}>
                  <OauthButton
                    scope={`${customScope}signup`}
                    provider="microsoft"
                  />
                  {oauthError && oauthError == "microsoft_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Grid>
                {/* <Grid item xs={12}>
                  <OauthButton
                    scope={`${customScope}signup`}
                    provider="google"
                  />
                  {oauthError && oauthError == "google_error" && (
                    <Typography style={{ color: theme.palette.error.main }}>
                      <FormattedMessage id={"oauth.errors." + oauthError} />
                    </Typography>
                  )}
                </Grid> */}

                <Grid item xs={12} style={{ paddingBottom: "0" }} mt={2}>
                  <TextDivider labelId="oauth.divider" />
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: "0" }}>
                <Button
                  fullWidth
                  variant="rounded"
                  color="primaryContained"
                  onClick={() => setSignUpWay("old")}
                >
                  <FormattedMessage id="form_sign_up" />
                </Button>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <Box style={{ marginBottom: "8px", textAlign: "center" }}>
              <Typography variant="body3">
                <Link
                  onClick={() => setSignUpWay("oauth")}
                  color="primary"
                  underline="always"
                  style={{ cursor: "pointer" }}
                >
                  <FormattedMessage id="go_back_oauth" />
                </Link>
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} style={{ marginTop: "32px" }}>
              <Grid container justifyContent="center">
                <Grid item xs={12}>
                  <Box
                    style={{ columnGap: "8px", display: "flex", width: "100%" }}
                  >
                    <Box style={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        required
                        type="text"
                        name="firstName"
                        size="small"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        variant="outlined"
                        label={
                          <FormattedMessage id="devise.signup.first_name" />
                        }
                        {...(errors["firstName"] && {
                          error: true,
                          helperText: errors["firstName"],
                        })}
                      ></TextField>
                    </Box>
                    <Box style={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        required
                        type="text"
                        size="small"
                        variant="outlined"
                        name="lastName"
                        onBlur={handleInputValue}
                        onChange={handleInputValue}
                        {...(errors["lastName"] && {
                          error: true,
                          helperText: errors["lastName"],
                        })}
                        label={
                          <FormattedMessage id="devise.signup.last_name" />
                        }
                      ></TextField>
                    </Box>
                  </Box>
                  <PhoneInput
                    country={"be"}
                    onlyCountries={countryMap.values}
                    containerStyle={{ marginTop: "8px", marginBottom: "8px" }}
                    inputStyle={{ height: "40px", width: "100%" }}
                    value={values.phone}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: false,
                      onBlur: handleInputValue,
                      onChange: handleInputValue,
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    size="small"
                    type="email"
                    variant="outlined"
                    onBlur={handleInputValue}
                    onChange={handleInputValue}
                    name="email"
                    {...(errors["email"] && {
                      error: true,
                      helperText: errors["email"],
                    })}
                    label={<FormattedMessage id="devise.signup.email" />}
                  ></TextField>
                  <PasswordField
                    name="password"
                    labelId="devise.signup.password"
                    handleInputValue={handleInputValue}
                    errors={errors}
                  />
                  <Button
                    fullWidth
                    id="step-two-signup-button"
                    type="submit"
                    variant="rounded"
                    color="primaryContained"
                    size="medium"
                    disabled={loading}
                    style={{ marginTop: "16px" }}
                  >
                    <Box justifyContent="center">
                      {loading ? (
                        <CircularProgress size={16} color="secondary">
                          <FormattedMessage id="devise.signup.build_career" />
                        </CircularProgress>
                      ) : (
                        <FormattedMessage id="devise.signup.build_career" />
                      )}
                    </Box>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
        <Grid container spacing={2} style={{ marginTop: "8px" }}>
          <Grid item xs={12}>
            <Typography variant="caption">
              <FormattedMessage
                id="devise.signup.c_v_candidate"
                values={{
                  link1: chunks => (
                    <Link href={`/${i18nLocale}/terms-of-use`}
                      style={{ cursor: "pointer", underline: "always" }}
                    >
                      {chunks}
                    </Link>
                  ),
                  link2: chunks => (
                    <Link href={`/${i18nLocale}/privacy-policy`}
                      style={{ cursor: "pointer", underline: "always" }}
                    >
                      {chunks}
                    </Link>
                  ),
                  link3: chunks => (
                    <Link href={`/${i18nLocale}/cookies`}
                      style={{ cursor: "pointer", underline: "always" }}
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box style={{ textAlign: "center" }}>
              <Typography variant="body3">
                <FormattedMessage id="devise.already_subscribed" />{" "}
                <Link
                  onClick={() => onSectionClicked("login")}
                  color="primary"
                  underline="always"
                  style={{ cursor: "pointer" }}
                >
                  <FormattedMessage id="signin" />
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SignUp;
