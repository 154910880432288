import React from "react";
import translations from "app/libs/i18n/translations.json";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import { Box } from "@mui/material";
import pluralize from "pluralize";
import sh from "./../utils/sh";

export const getHardSkillsText = (skills) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const hardSkills = skills
    ?.filter((skill) => skill.category == "hard")
    ?.slice(0, 3);
  let hardSkillsText = "";
  if (hardSkills && hardSkills?.length > 0) {
    let skills = [];
    for (let i = 0; i < hardSkills.length; i++) {
      skills.push(
        translations["skills_list"][`${i18nLocale}.hard.${hardSkills[i]?.name}`]
      );
    }
    hardSkillsText = skills?.join(" - ");
    if (hardSkills.length > 2) hardSkillsText += " - ...";
  }
  return hardSkillsText;
};

export const getYearMessageId = (year) => {
  if (!year) return "undefined";
  else return year > 1 ? "candidate.years" : "candidate.year";
};

export const getTranslatedRegionsArray = (intl, regions) => {
  if (!regions || regions?.length <= 0 || regions[0] == null) {
    return <FormattedMessage id="undefined" />;
  }
  let translatedArray = regions?.map((value) =>
    intl.formatMessage({ id: `regions.${value}` })
  );
  return translatedArray?.join(", ");
};

export const getHtmlText = (htmlText) => {
  const elem = document.createElement("div");
  elem.innerHTML = htmlText;
  const text = elem.innerText.replace(/\u200B/g, "");
  elem.remove();
  return text;
};

export const pulses = makeStyles(() => ({
  pulse: {
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
    transform: "scale(1)",
    animation: "$pulse 2s ",
  },
  "@keyframes pulse": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0.7)",
    },
    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(0, 0, 0, 0)",
    },
    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  },
}));

export const getDurationMessage = (job) => {
  const intl = useIntl();
  let id = "";
  let value = {};

  let expirationDiff = new Date(job.end_of_mandate) - Date.now();
  let creationDiff = Date.now() - new Date(job.published_at);

  let creationDiffInMinutes = creationDiff / (60 * 1000);
  let creationDiffInHours = creationDiffInMinutes / 60;
  let creationDiffInDays = creationDiffInHours / 24;

  let minutes = expirationDiff / (60 * 1000);
  let hour = minutes / 60;
  let days = hour / 24;
  const nineOnetyDaysInMinutes = 132469;
  const fourtyFiveDaysInMinutes = nineOnetyDaysInMinutes / 2;
  const oneDayInMinutes = 1440;
  const oneHourInMinutes = 60;

  if (!job?.published_at) {
    id = "job_offer.unpublished";
  } else if (!job?.end_of_mandate) {
    id = "job_offer.posted_n_minutes_ago";
    value = { minutes: Math.floor(creationDiffInMinutes) };
  } else if (minutes <= fourtyFiveDaysInMinutes) {
    if (minutes <= oneDayInMinutes) {
      id = "job_offer.posted_last_day_expired";
    } else {
      id = "job_offer.posted_n_days_expired";
      value = { days: Math.floor(days) };
    }
  } else if (minutes > fourtyFiveDaysInMinutes) {
    if (creationDiffInMinutes > oneDayInMinutes) {
      id = "job_offer.posted_n_days_ago";
      value = { days: Math.floor(creationDiffInDays) };
    } else if (creationDiffInMinutes > Math.floor(oneHourInMinutes)) {
      id = "job_offer.posted_n_hours_ago";
      value = { hours: Math.floor(creationDiffInHours) };
    } else if (creationDiffInMinutes <= oneHourInMinutes) {
      id = "job_offer.posted_n_minutes_ago";
      value = { minutes: Math.floor(creationDiffInMinutes) };
    }
  }

  return intl.formatMessage({ id: id }, value);
};

export const getDuration = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

  if (days < 7) {
    return (
      <FormattedMessage
        id="job_offer.number_of_days"
        values={{
          days: days,
        }}
      />
    );
  } else if (days < 30) {
    const weeks = Math.ceil((days / 7) * 2) / 2;
    return (
      <FormattedMessage
        id="job_offer.number_of_weeks"
        values={{
          weeks: weeks,
        }}
      />
    );
  } else if (days < 365) {
    let months =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth());
    const diffDays = end.getDate() - start.getDate();

    if (diffDays > 0) {
      months += diffDays / 30;
    }
    const roundedMonths = Math.ceil(months * 2) / 2;

    return (
      <FormattedMessage
        id="job_offer.number_of_months"
        values={{
          diffInMonths: roundedMonths,
        }}
      />
    );
  } else {
    const years = end.getFullYear() - start.getFullYear();
    return (
      <FormattedMessage
        id="job_offer.number_of_years"
        values={{
          years: years,
        }}
      />
    );
  }
};

export const formatJobDuration = (jobOffer) => {
  let currentDate = moment().format();

  if (jobOffer?.end_date) {
    return getDuration(jobOffer.start_date, jobOffer.end_date);
  } else if (moment(jobOffer.start_date).isBefore(currentDate)) {
    return <FormattedMessage id="asap" />;
  } else if (jobOffer?.start_date) {
    return moment(jobOffer.start_date).locale("fr").format("l");
  } else {
    return <FormattedMessage id="asap" />;
  }
};

export const getProfileCreation = (profile) => {
  const intl = useIntl();
  let minutes = moment().diff(profile.created_at, "minutes");
  let days = moment().diff(profile.created_at, "days");
  let months = moment().diff(profile.created_at, "months");
  let hours = moment().diff(profile.created_at, "hours");
  let years = moment().diff(profile.created_at, "years");
  let id = "candidate_search.profile_created_n_days_ago";
  let value = {};
  if (days < 1 && minutes < 60) {
    value = { time: hours };
    id = "candidate_search.profile_created_n_minutes_ago";
  } else if (days < 1 && minutes > 60) {
    value = { time: hours };
    id = "candidate_search.profile_created_n_minutes_ago";
  } else if (days == 1 || days <= 30) {
    value = { time: days };
    id = "candidate_search.profile_created_n_days_ago";
  } else if (days >= 30 || days <= 365) {
    value = { time: months };
    id = "candidate_search.profile_created_n_months_ago";
  } else if (days > 365) {
    value = { time: years };
    id = "candidate_search.profile_created_n_years_ago";
  }
  return intl.formatMessage({ id: id }, value);
};

export const getOfferStatus = (offer, maxExpiration = 5) => {
  switch (offer.offer_status) {
  case "online":
    // eslint-disable-next-line no-case-declarations
    let expirationDiffInDays =
      (new Date(offer.end_of_mandate) - Date.now()) / 86400000;
    if (expirationDiffInDays > maxExpiration) {
      return { id: "job_offer_summary.online", values: {} };
    } else {
      return {
        id: "job_offer_summary.expire_soon",
        values: {
          days: Math.round(expirationDiffInDays),
          b: (chunks) => <b>{chunks}</b>,
        },
      };
    }
  case "closed":
    return { id: "job_offer_summary.closed", values: {} };
  default:
    return { id: "job_offer_summary.draft", values: {} };
  }
};

export const getFormattedDate = (date, withTime = true) => {
  if (!date) return "";

  if (withTime) {
    return new Intl.DateTimeFormat("fr-FR", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(Date.parse(date));
  } else {
    return new Intl.DateTimeFormat("fr-FR", {
      dateStyle: "short",
    }).format(Date.parse(date));
  }
};

export const capitalize = (str) => {
  if (!str) return "";

  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase();
};

export const getUrlAnchor = (url, label = null, color = "#2A334E") => {
  if (!url) return "";
  if (!label) label = url;

  let href = url.includes("http") ? url : `http://${url}`;
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textDecoration: "underline",
        color: color,
      }}
    >
      {label}
    </a>
  );
};

export const strip = (str) => {
  let div = document.createElement("div");
  div.innerHTML = str;
  let text = div.textContent || div.innerText || "";

  return text !== "" ? text : null;
};

export const handleRecordClicked = (recordType, recordId) => {
  if (!recordType || !recordId) return;

  window.location.href = `${window.location.origin}/admin/all_${pluralize(
    recordType
  )}/${recordId}`;
};

export const getLinkedRecordLink = (label) => {
  if (!label || label.replaceAll(" ", "") == "") return;

  const theme = useTheme();

  return (
    <Box style={{ color: theme.palette.primary.main, cursor: "pointer" }}>
      {label}
    </Box>
  );
};

export const creditTypes = [
  { value: "job_offer_creation", label: "Job offer creation" },
  { value: "cv_access", label: "CV-Thèque" },
];

export const creditStatuses = [
  { value: "pending", label: "Pending" },
  { value: "available", label: "Available" },
  { value: "activated", label: "Activated" },
  { value: "expired", label: "Expired" },
];

export const truncate = (str, maxLength) => {
  if (!str) return "";

  return str.length > maxLength ? str.substring(0, maxLength - 1) + "…" : str;
};

export const formatMonetaryNumber = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertIntegerToFormatCurrency = (money) => {
  let currency = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: 0,
  }).format(money);
  return currency;
};

export const formatStringToRange = (remuneration) => {
  if (!remuneration) return "0 - 0";

  return remuneration.includes("-") ? remuneration : `0 - ${remuneration}`;
};

export const incrementJobOfferMetric = (
  jobOffer,
  metricType,
  authenticityToken,
  publicJob = false
) => {
  sh.post(`/${publicJob ? "public_" : ""}job_offers/${jobOffer.id}`, {
    metric_type: metricType,
    authenticity_token: authenticityToken,
  }).catch((err) => {
    console.log(err);
  });
};

export const incrementWebsiteClickCount = (companyId, authenticityToken) => {
  sh.post(`/company/${companyId}/increment_website_click`, {
    authenticity_token: authenticityToken,
  }).catch((err) => {
    console.error("Error incrementing website click count:", err);
  });
};

export const annualyRemuneration = (remuneration) => {
  const intl = useIntl();
  let remunerationRange = "";
  if (remuneration == null || remuneration == "" || remuneration == "0-0") {
    remunerationRange = intl.formatMessage({ id: "to_discuss" });
  } else if (remuneration.includes("-")) {
    remunerationRange = remuneration + "K €";
  } else {
    remunerationRange = remuneration + "€";
  }
  return remunerationRange;
};

export const sortDateRange = (records) => {
  if (!records || records.length <= 0) return [];

  return records.sort(function (exp1, exp2) {
    if (!exp2.end_date && !exp1.end_date) {
      return Date.parse(exp1.start_date) - Date.parse(exp2.start_date);
    } else if (!exp2.end_date) {
      return 1;
    } else if (!exp1.end_date) {
      return -1;
    } else {
      return Date.parse(exp2.end_date) - Date.parse(exp1.end_date);
    }
  });
};

export const stripHtml = (html) => {
  if (!html) return "";

  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const formatJobOfferLocation = (location) => {
  const intl = useIntl();
  if (location.city) return capitalize(location.city);
  if (location.region)
    return intl.formatMessage({ id: `regions.${location.region}` });
  if (location.district)
    return intl.formatMessage({ id: `districts.${location.district}` });

  return intl.formatMessage({ id: `countries.${location.country}` });
};

export const formatJobOfferLocations = (locations) => {
  return locations.map((location) => {
    return formatJobOfferLocation(location);
  });
};

export const formatFileSize = (bytes, decimals = 0) => {
  if (bytes == 0) return "0 Bytes";
  var k = 1024,
    dm = decimals,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const isToday = (date) => {
  const today = moment().startOf("day");
  const applicationDate = moment(date).startOf("day");
  return today.isSame(applicationDate);
};

export const getDenialReasons = (denialReasons, i18nLocale = "en") => {
  if (!denialReasons || denialReasons.length === 0) {
    return <FormattedMessage id="application.closed" />;
  }

  const reasonsList = denialReasons.map(reason =>
    translations[i18nLocale][`applicant.denial_reasons.${reason}`] || reason
  );

  if (reasonsList.length === 1) {
    return reasonsList[0];
  }

  return reasonsList.map(reason => `- ${reason}`).join("\n");
};

export const handleLinkedinShare = (url) => {
  const left = screen.width / 2 - 250;
  const top = screen.height / 2 - 250;
  window.open(
    "https://www.linkedin.com/sharing/share-offsite/?url=" +
    encodeURIComponent(url),
    "",
    `_blank, width=500, height=500, resizable=yes, scrollbars=yes, top=${top}, left=${left}`
  );
};

export const handleCopyClick = (url, intl, enqueueSnackbar) => {
  navigator.clipboard.writeText(url)
    .then(() => {
      enqueueSnackbar(intl.formatMessage({ id: "settings.copy_in_clipboard" }), {
        variant: "success",
      });
    })
    .catch(() => {
      enqueueSnackbar(intl.formatMessage({ id: "settings.copy_failed" }), {
        variant: "error",
      });
    });
};

export const getFullPortfolioURL = (url) => {
  if (!url) return "";
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};

export const getFirstPathParam = () => {
  const pathName = window.location.pathname;
  const pathSegments = pathName.split("/").filter(Boolean);
  return pathSegments[1];
};
